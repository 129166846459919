import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import {CookieService} from 'ngx-cookie-service';
import {EMPTY, Observable, of, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ALLOWED_GENERAL_ROLES, ALLOWED_ROLE_PREFIX} from '../allowed-role-prefix';
import {StringUtils} from '../utils/string-utils';
import {AppRedirectService} from './app-redirect.service';
import {
    AUTH_BASE_COOKIE_DOMAIN, AUTH_BASE_URL,
    changeEmploymentUrl,
    getEmploymentsUrl,
    getLoginUrl,
    getLogoutUrl,
} from './auth.urls';
import {LoginResourceModel} from './model/login-resource.model';
import {environment, SYSTEM_NAME, SYSTEM_NAME_AUTH} from '../../../environments/environment.dev';
import {
    AUTH_REFRESH_TOKEN_EXPIRATION_THRESHOLD,
    AUTH_TOKEN_EXPIRATION_CHECK_THRESHOLD,
    AUTH_TOKEN_EXPIRATION_THRESHOLD, AUTH_TOKEN_REFRESH_INTERVAL,
} from './auth-constants';
import {SystemUserModel} from '../../application/models/system-user.model';
import {getBaseFrontendUrl} from '../urls';

export const localStorageToken = `${environment.systemName.toLowerCase()}user`;

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    private mIsRefreshingToken = false;
    private mRefreshTokenInterval;

    public token: string;

    static encryptData(data): string {
        return data;
    }

    static decryptData(data): string {
        return data;
    }

    static updateUserData(data): void {
        const currentUser = AuthenticationService.getStoredAuthData();
        if (!currentUser) {
            return;
        }

        const user = {
            user: data,
            expires: currentUser.expires,
            refresh_expires: currentUser.refresh_expires,
            access_token: currentUser.access_token,
            refresh_token: currentUser.refresh_token,
            session_guid: currentUser.session_guid,
        };
        const encrypted = AuthenticationService.encryptData(JSON.stringify(user));

        localStorage.removeItem(`${localStorageToken}`);
        localStorage.setItem(`${localStorageToken}`, encrypted.toString());
    }

    /* ### Save logged user to local storage ### */
    static saveAuthData(response) {
        if (!response) {
            return;
        }

        const jsonData = response;
        const user = {
            user: jsonData.user,
            expires: jsonData.expires,
            refresh_expires: jsonData.refresh_expires,
            access_token: jsonData.access_token,
            refresh_token: jsonData.refresh_token,
            session_guid: jsonData.session_guid,
        };

        const encrypted = AuthenticationService.encryptData(JSON.stringify(user));

        localStorage.setItem(`${localStorageToken}`, encrypted.toString());
    }

    static getStoredAuthData() {
        try {
            const sessionJson = localStorage.getItem(`${localStorageToken}`);

            if (StringUtils.isNullOrWhitespace(sessionJson)) {
                return undefined;
            }

            const decrypted = AuthenticationService.decryptData(sessionJson);

            if (!sessionJson || !decrypted) {
                localStorage.removeItem(`${localStorageToken}`);
                return undefined;
            }

            return StringUtils.isNullOrWhitespace(decrypted) ? undefined : JSON.parse(decrypted);
        } catch (e) {

            localStorage.removeItem(`${localStorageToken}`);

            if (!environment.production) {
                console.error(e);
            }
        }

        return undefined;
    }

    static updateAuthData(response) {
        if (!response) {
            return;
        }

        const jsonData = response;
        const currentUser = AuthenticationService.getStoredAuthData();

        if (!currentUser) {
            return;
        }

        const user = {
            user: jsonData,
            expires: currentUser.expires,
            refresh_expires: currentUser.refresh_expires,
            access_token: currentUser.access_token,
            refresh_token: currentUser.refresh_token,
            session_guid: currentUser.session_guid,
        };
        const encrypted = AuthenticationService.encryptData(JSON.stringify(user));

        localStorage.removeItem(`${localStorageToken}`);
        localStorage.setItem(`${localStorageToken}`, encrypted.toString());
    }

    static updateCurrentUser(
        accessToken: string,
        refreshToken: string,
        expirationTime: number,
        refreshExpirationTime: number,
        sessionGuid: string,
    ) {
        const currentUser = AuthenticationService.getStoredAuthData();

        if (!currentUser) {
            return;
        }

        localStorage.removeItem(`${localStorageToken}`);
        currentUser.access_token = accessToken;
        currentUser.refresh_token = refreshToken;
        currentUser.expires = expirationTime;
        currentUser.refresh_expires = refreshExpirationTime;
        currentUser.session_guid = sessionGuid;

        const encrypted = AuthenticationService.encryptData(JSON.stringify(currentUser));

        localStorage.setItem(`${localStorageToken}`, encrypted.toString());
    }

    static getLoggedUser(): SystemUserModel | null {
        let data = AuthenticationService.getStoredAuthData();

        let user = null;

        if (data && data.user) {
            user = data.user as SystemUserModel;
        }

        return user;
    }

    static getServerLocalTimeDifference() {
        let xmlHttp;
        try {
            xmlHttp = new XMLHttpRequest();
        } catch (err1) {
            throw new Error();
        }
        xmlHttp.open('HEAD', window.location.href.toString(), true);
        xmlHttp.setRequestHeader('Content-Type', 'text/html');
        xmlHttp.send('');
        const serverTime = moment(xmlHttp.getResponseHeader('Date'));
        const localTime = moment(new Date());
        return serverTime.diff(localTime, 'seconds');
    }

    static checkExpirationTime(): boolean {
        const currentUser = AuthenticationService.getStoredAuthData();

        if (!currentUser) {
            return false;
        }

        const expires = currentUser.expires;
        const now = Date.now();
        const timeDiff = expires - now;

        return expires > now && timeDiff > AUTH_TOKEN_EXPIRATION_THRESHOLD;
    }

    static isExpirationTimeClosingLimit(): boolean {
        const currentUser = AuthenticationService.getStoredAuthData();

        if (!currentUser) {
            return false;
        }

        const expires = currentUser.expires;
        const now = Date.now();
        const timeDiff = expires - now;

        if (expires <= now) {
            return false;
        }

        return timeDiff <= AUTH_TOKEN_EXPIRATION_CHECK_THRESHOLD;
    }

    static checkRefreshExpirationTime(): boolean {
        const currentUser = AuthenticationService.getStoredAuthData();

        if (!currentUser) {
            return false;
        }

        const expires = currentUser.refresh_expires;
        const now = Date.now();
        const timeDiff = expires - now;

        return expires > now && timeDiff > AUTH_REFRESH_TOKEN_EXPIRATION_THRESHOLD;
    }

    static isSessionExpired(): boolean {
        const expired = !AuthenticationService.checkExpirationTime() && !AuthenticationService.checkRefreshExpirationTime();

        return expired;
    }

    static checkSystemAccessPermission(systemName: string = environment.systemName.toUpperCase()): boolean {
        const currentUser = AuthenticationService.getStoredAuthData();

        if (!currentUser) {
            return false;
        }

        const roles = currentUser.user.roles || [];

        // return true if ALLOWED_ROLE_PREFIX is defined for the system or if the user has role from ALLOWED_GENERAL_ROLES
        return ALLOWED_ROLE_PREFIX[systemName] || roles.some(role => ALLOWED_GENERAL_ROLES.includes(role));
    }

    get tokenRefreshInProgress(): boolean {
        return this.mIsRefreshingToken;
    }

    constructor(
        private http: HttpClient,
        private router: Router,
        private cookieService: CookieService,
        private appRedirectService: AppRedirectService,
    ) {
        if (AuthenticationService.getServerLocalTimeDifference() > 600) {
            console.log('Bad Time!');
            this.errorMessage(1);
            this.removeUserAndCookie();
            this.appRedirectService.redirectLogin(false);
        }

        clearInterval(this.mRefreshTokenInterval);

        console.log('load auth service');

        this.mRefreshTokenInterval = setInterval(() => {
            if (this.mIsRefreshingToken) {
                return;
            }

            const currentUser = AuthenticationService.getStoredAuthData();

            console.log('currentUser:: ', currentUser)

            if (!_.isNil(currentUser)) {
                this.getNewToken(currentUser.user.login, currentUser.refresh_token, currentUser.session_guid).subscribe({
                    next: (user: any) => {
                        console.log('refresh user:: ', user);
                    },
                    error: (err: any) => {
                        // console.log(err);
                    },
                });
            }
        }, AUTH_TOKEN_REFRESH_INTERVAL);
    }

    saveLoggedUserFromEncrypted(cipher: string): void {
        if (!cipher) {
            return;
        }

        const decrypted = AuthenticationService.decryptData(cipher);

        if (!StringUtils.isNullOrWhitespace(decrypted) && decrypted !== 'undefined') {
            const parsed = JSON.parse(decrypted);

            this.saveUserCookie(parsed);

            AuthenticationService.saveAuthData(parsed);
        }
    }

    checkUser(): boolean {
        if (!AuthenticationService.checkExpirationTime() && !this.mIsRefreshingToken) {
            const currentUser = AuthenticationService.getStoredAuthData();

            if (!currentUser) {
                return false;
            }

            this.getNewToken(currentUser.user.login, currentUser.refresh_token, currentUser.session_guid).subscribe({
                error: error => {
                    console.log('Error while updating refresh token for user ', error);
                },
            });
        }

        return true;
    }

    checkTokenRefreshRequirement(): void {
        if (AuthenticationService.isExpirationTimeClosingLimit() && !this.mIsRefreshingToken) {
            const currentUser = AuthenticationService.getStoredAuthData();

            if (!currentUser) {
                return;
            }

            this.getNewToken(currentUser.user.login, currentUser.refresh_token, currentUser.session_guid).subscribe({
                error: error => {
                    console.log('Error while updating refresh token for user ', error);
                },
            });
        }
    }

    getCurrentUser(): Observable<any> {
        return this.http
            .get(`${environment.API_URL}/auth/getCurrentUser`);
    }

    getEmployments(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: `Bearer ${AuthenticationService.getStoredAuthData().access_token}`,
            }),
        };

        return this.http.post(getEmploymentsUrl(), {}, httpOptions)
            .pipe(
                catchError(e => {
                    return e;
                }),
            );
    }

    changeEmployment(employmentCode): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: `Bearer ${AuthenticationService.getStoredAuthData().access_token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
        };
        const body = `employmentCode=${employmentCode}`;
        return this.http.post(changeEmploymentUrl(), body, httpOptions).pipe(
            map(response => {
                AuthenticationService.updateAuthData(response);
                return of(response);
            }),
            catchError(e => {
                throw e;
            }),
        );
    }

    login(username: string, password: string): Observable<boolean> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };
        const body = new LoginResourceModel(_.trim(username), password);

        return this.http.post(getLoginUrl(), body, httpOptions).pipe(
            map((response: any) => {
                if (!StringUtils.isNullOrWhitespace(response.access_token)) {
                    AuthenticationService.saveAuthData(response);
                    this.saveUserCookie(response);
                    return true;
                } else {
                    return false;
                }
            }),
            catchError(e => {
                throw e;
            }),
        );
    }

    logout(): Observable<boolean> {

        const data = AuthenticationService.getStoredAuthData();

        let token = null;

        if (data && data.access_token) {
            token = data.access_token
        }

        const resultHandler = () => {
            this.token = null;
            this.removeUserAndCookie();
        };

        if (token) {

            const isExternal = data.user.isExternal || false;

            if (!isExternal) {

                const returnUrl = getBaseFrontendUrl(SYSTEM_NAME_AUTH);
                const url = `${AUTH_BASE_URL}/sso/logout?retUrl=${encodeURIComponent(returnUrl)}&access_token=${encodeURIComponent(token)}`;

                window.open(url, '_self');

                this.removeUserAndCookie();
                this.token = null;

                return of(true);
            }

            const httpOptions = {
                headers: new HttpHeaders({
                    Authorization: token,
                }),
            };

            if (this.checkForUserCookie(false)) {
                return this.http.post(getLogoutUrl(), {}, httpOptions).pipe(
                    map(() => {
                        resultHandler();

                        return true;
                    }),
                    catchError(error => {
                        resultHandler();

                        return throwError(error);
                    }),
                );
            }

        }

        resultHandler();

        return of(true);
    }

    getNewToken(username, refreshToken, sessionGuid): Observable<any> {
        if (this.mIsRefreshingToken) {
            return EMPTY;
        }

        this.mIsRefreshingToken = true;

        const httpOptions: any = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${refreshToken}`
            }),
            responseType: 'json',
        };

        return this.http.get(getLoginUrl(), httpOptions).pipe(
            map((res: any) => {
                const result = res;
                this.mIsRefreshingToken = false;

                this.saveUserCookie(result);
                AuthenticationService.updateCurrentUser(
                    result.access_token,
                    result.refresh_token,
                    result.expires,
                    result.refresh_expires,
                    result.session_guid,
                );

                return result;
            }),
            catchError(e => {
                this.mIsRefreshingToken = false;

                throw e;
            }),
        );
    }

    errorMessage(type: number) {
    }

    checkForUserCookie(updateSessionFromCookie = true): boolean {
        const session = AuthenticationService.getStoredAuthData();
        const cookie = this.getUserCookie();

        if (!_.isNil(session) && !cookie) {
            this.removeUserAndCookie();

            return false;
        } else if (updateSessionFromCookie && this.isUpdateFromCookieValid(session, cookie)) {
            if (cookie) {
                AuthenticationService.saveAuthData(cookie);

                if (AuthenticationService.isSessionExpired()) {
                    this.removeUserAndCookie();

                    return false;
                }

                return true;
            }
            this.removeUserAndCookie();

            return false;
        } else if (_.isNil(session) || AuthenticationService.isSessionExpired()) {
            this.removeUserAndCookie();

            return false;
        }

        return true;
    }

    private isUpdateFromCookieValid(user: any, cookie: any): boolean {
        if (!cookie || !cookie.session_guid || !cookie.refresh_token) {
            return false;
        }

        if (!user || !user.session_guid || cookie.session_guid !== user.session_guid) {
            return true;
        }

        let cookieExp;

        if (cookie.expires) {
            cookieExp = cookie.expires;
        } else if (cookie.expires_in) {
            cookieExp = Number.parseInt(cookie.expires_in, 0) * 1000 + Date.now();
        }

        const userExp = Number.parseInt(user.expires, 0);

        if (cookieExp && cookieExp === userExp) {
            return false;
        }

        if (
            cookieExp > userExp &&
            cookie.session_guid === user.session_guid &&
            cookie.refresh_token !== user.refresh_token
        ) {
            return true;
        }

        const diff = cookieExp - userExp;

        return diff > AUTH_TOKEN_EXPIRATION_THRESHOLD;
    }

    private saveUserCookie(user: any): void {
        if (!user) return;

        user._fromCookie = true;
        const encrypted = AuthenticationService.encryptData(JSON.stringify(user));

        // Split encrypted data into chunks if it exceeds the cookie size limit
        const chunkSize = 3000; // Each chunk is around 3 KB to fit comfortably within the 4 KB limit
        const chunks = this.splitStringIntoChunks(encrypted, chunkSize);

        // Save each chunk as a separate cookie
        const baseCookieName = environment.production ? 'auth_user' : 'devauth_user';
        chunks.forEach((chunk, index) => {
            const cookieName = `${baseCookieName}_part${index}`;
            this.cookieService.set(
                cookieName,
                chunk,
                undefined,
                '/',
                AUTH_BASE_COOKIE_DOMAIN,
                environment.production
            );
        });

        // Save the total number of chunks in an additional cookie
        this.cookieService.set(
            `${baseCookieName}_partsCount`,
            chunks.length.toString(),
            undefined,
            '/',
            AUTH_BASE_COOKIE_DOMAIN,
            environment.production
        );
    }

    private getUserCookie(): any {
        const baseCookieName = environment.production ? 'auth_user' : 'devauth_user';
        const partsCountStr = this.cookieService.get(`${baseCookieName}_partsCount`);
        const partsCount = parseInt(partsCountStr, 10);

        if (isNaN(partsCount) || partsCount <= 0) {
            return undefined;
        }

        let encryptedData = '';
        for (let i = 0; i < partsCount; i++) {
            const chunk = this.cookieService.get(`${baseCookieName}_part${i}`);
            if (chunk) {
                encryptedData += chunk;
            } else {
                console.error(`Missing cookie part ${i}. Unable to reconstruct user data.`);
                return undefined;
            }
        }

        // Decrypt the reconstructed data
        const decrypted = AuthenticationService.decryptData(encryptedData);
        return StringUtils.isNullOrWhitespace(decrypted) ? undefined : JSON.parse(decrypted);
    }

    private removeUserAndCookie(): void {
        const baseCookieName = environment.production ? 'auth_user' : 'devauth_user';
        const partsCountStr = this.cookieService.get(`${baseCookieName}_partsCount`);
        const partsCount = parseInt(partsCountStr, 10);

        // Remove all chunk cookies if they exist
        if (!isNaN(partsCount) && partsCount > 0) {
            for (let i = 0; i < partsCount; i++) {
                const cookieName = `${baseCookieName}_part${i}`;
                this.cookieService.delete(cookieName, '/', AUTH_BASE_COOKIE_DOMAIN);
            }
            this.cookieService.delete(`${baseCookieName}_partsCount`, '/', AUTH_BASE_COOKIE_DOMAIN);
        }

        // Also clear localStorage if applicable
        localStorage.removeItem(SYSTEM_NAME.toLowerCase() + 'user');
    }

// Utility function to split a string into smaller chunks
    private splitStringIntoChunks(str: string, chunkSize: number): string[] {
        const chunks: string[] = [];
        let index = 0;

        while (index < str.length) {
            chunks.push(str.slice(index, index + chunkSize));
            index += chunkSize;
        }

        return chunks;
    }
}
