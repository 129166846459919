import {Injectable} from '@angular/core';
import {firstValueFrom, Observable, of, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment.dev';
import {map} from 'rxjs/operators';

export enum CODEBOOK_ITEMS {
    faculties = "faculties",
    departments = "departments",
}

@Injectable({
    providedIn: 'root',
})
export class CodebooksService {

    protected storageKey = 'codebookItems';

    preferencesChanged: Subject<any> = new Subject<any>();

    protected values: { [key: string]: any } = {};

    private isLoading: { [key: string]: boolean } = {};

    constructor(
        private http: HttpClient
    ) {
    }

    getFaculties(): Observable<any[]> {
        return this.http.get(`${environment.API_URL}/codebook/getFacultiesWithHistorical`).pipe(map((res: any) => res.items));
    }

    getDepartments(facultyId: number = null): Observable<any[]> {
        if (facultyId === null) {
            return this.http.get(`${environment.API_URL}/codebook/getDepartments`).pipe(map((res: any) => res.items));
        }
        return this.http.get(`${environment.API_URL}/codebook/getDepartments/${facultyId}`).pipe(map((res: any) => res.items));
    }

    getFacultyByFacultyId(facultyId: number): Observable<any> {
        return this.http.get(`${environment.API_URL}/faculty?keys=${facultyId}`);
    }

    getDepartmentByDepartmentId(departmentId: number): Observable<any> {
        return this.http.get(`${environment.API_URL}/department?keys=${departmentId}`);
    }

    async get(key: string, defaultValue = undefined) {
        if (typeof this.values[key] !== "undefined") {
            return this.values[key];
        }

        if (this.values[key]) {
            return of(this.values[key]);
        }

        if (this.isLoading[key]) {
            return new Observable((observer) => {
                this.preferencesChanged.subscribe((data) => {
                    if (data.key === key) {
                        observer.next(data.value);
                        observer.complete();
                    }
                });
            });
        }

        let items = [];

        items = this.checkDataFromLocalStorage(key);

        if (!items) {
            this.isLoading[key] = true;

            switch (key) {
                case CODEBOOK_ITEMS.faculties:
                    items = await firstValueFrom(this.getFaculties());
                    break;
                case CODEBOOK_ITEMS.departments:
                    items = await firstValueFrom(this.getDepartments());
                    break;
            }
        }

        if (items) {
            this.isLoading[key] = false;

            this.values[key] = items;

            this.set(key, items, true);
        }

        return items;
    }

    checkDataFromLocalStorage(key: string) {
        const localData = window.localStorage.getItem(this.storageKey);
        if (localData) {
            const data = JSON.parse(localData);
            if (data[key]) {
                return data[key];
            }
        }
        return null;
    }

    set(key: string, value: any, save = true) {
        this.values[key] = value;
        if (save) {
            this.saveToLocalStorage();
        }
        this.preferencesChanged.next({ key, value });
    }

    saveToLocalStorage() {
        window.localStorage.setItem(this.storageKey, JSON.stringify(this.values));
    }

    async preloadCodebooks() {
        // await this.get(CODEBOOK_ITEMS.faculties);
        // await this.get(CODEBOOK_ITEMS.departments);
    }
}
