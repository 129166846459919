// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.dev.ts` with `environmentDev.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const SYSTEM_NAME = 'AUTOPREVADZKA';
export const SYSTEM_NAME_AUTH = 'AUTH';

export enum UserRolesEnum {
    SuperAdmin = 'SUPERADMIN',
    CarUser = 'CAR_USER',
    CarAdmin = 'CAR_ADMIN',
    CarDep = 'CAR_DEP',
    CarRtu = 'CAR_RTU',
    CarOhse = 'CAR_OHSE',
    OhseAdmin = 'OHSE_ADMIN'
}

export const environment = {
    production: false,
    useHttps: true,
    isLocal: true,
    systemName: SYSTEM_NAME,
    organizationName: 'Technická univerzita v Košiciach',
    organizationWebsiteUrl: 'https://www.tuke.sk/',
    organizationSupportEmail: '',
    organizationSupportPhone: '',
    availableLanguages: ['sk', 'en'],
    defaultLocale: 'sk',
    API_URL: `https://api.autoprevadzka.portal.dev.msweb.tuke.sk/api`,
    BASE_API_URL: `https://auth.api.portal.dev.msweb.tuke.sk`,
    userRoles: {
        All: [
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.CarUser,
            UserRolesEnum.CarAdmin,
            UserRolesEnum.CarDep,
            UserRolesEnum.CarRtu,
            UserRolesEnum.CarOhse,
            UserRolesEnum.OhseAdmin
        ],
        Approvers: [
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.CarAdmin,
            UserRolesEnum.CarDep,
            UserRolesEnum.CarRtu,
            UserRolesEnum.CarOhse,
            UserRolesEnum.OhseAdmin
        ],
        ExtendedApprovers: [
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.CarAdmin
        ],
        Admins: [
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.CarAdmin,
            UserRolesEnum.CarRtu,
            UserRolesEnum.CarOhse,
            UserRolesEnum.OhseAdmin
        ],
        Users: [
            UserRolesEnum.CarUser
        ]
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
