// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#loading {
  position: fixed;
  z-index: 999999;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.27);
  display: none;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.ts"],"names":[],"mappings":"AAEQ;EACI,eAAA;EACA,eAAA;EACA,aAAA;EACA,YAAA;EACA,MAAA;EACA,OAAA;EACA,+BAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AADZ","sourcesContent":["\n\n        #loading {\n            position: fixed;\n            z-index: 999999;\n            height: 100vh;\n            width: 100vw;\n            top: 0;\n            left: 0;\n            background: rgba(0, 0, 0, 0.27);\n            display: none;\n            justify-content: center;\n            align-items: center;\n        }\n\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
