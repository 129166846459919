export enum AppRoutesEnum {
    dashboard = 'dashboard',
    calendar = 'calendar',
    profile = 'profile',
    settings = 'settings',
    newRequest = 'request',
    myRequests = 'my-requests',
    requestManagement = 'request-management',
    manageRequest = 'request/manage',
    organizationalUnits = 'organizational-units',
    fleetManagement = 'fleet-management',
    ohse = 'ohse',
    rectorsOffice = 'rectors-office',
    vehicleManagement = 'vehicle-management',
    driverManagement = 'driver-management',
}
