// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  position: relative;
  width: 100%;
  height: 100%;
}

.image {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image.sm {
  width: 50px;
  height: 50px;
}
.image.md {
  width: 80px;
  height: 80px;
}
.image.lg {
  width: 110px;
  height: 110px;
}
.image.elg {
  width: 150px;
  height: 150px;
}

img {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/common/components/inline-loader/inline-loader.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;AACF;;AAIE;EACE,WAAA;EACA,YAAA;AADJ;AAIE;EACE,WAAA;EACA,YAAA;AAFJ;AAKE;EACE,YAAA;EACA,aAAA;AAHJ;AAME;EACE,YAAA;EACA,aAAA;AAJJ;;AASA;EACE,WAAA;AANF","sourcesContent":[":root {\n  position: relative;\n  width: 100%;\n  height: 100%;\n}\n\n.image {\n  width: 50px;\n  height: 50px;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.image {\n\n  &.sm {\n    width: 50px;\n    height: 50px;\n  }\n\n  &.md {\n    width: 80px;\n    height: 80px;\n  }\n\n  &.lg {\n    width: 110px;\n    height: 110px;\n  }\n\n  &.elg {\n    width: 150px;\n    height: 150px;\n  }\n\n}\n\nimg {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
