// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-header {
  color: red;
  font-weight: bold;
  font-size: 3em;
  text-align: center;
}

.info-row-label {
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/app/application/dev/dev.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,iBAAA;EACA,cAAA;EACA,kBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ","sourcesContent":[".info-header {\n    color: red;\n    font-weight: bold;\n    font-size: 3em;\n    text-align: center;\n}\n\n.info-row-label {\n    text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
