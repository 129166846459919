import { Theme } from "./theme";

export enum ThemesEnum {
    DEFAULT = 'default',
    EKF = 'ekf',
    FBERG = 'fberg',
    FEI = 'fei',
    FMMR = 'fmmr',
    FU = 'fu',
    FVT = 'fvt',
    LF = 'lf',
    SJF = 'sjf',
    SVF = 'svf'
}

export const themesList: Theme[] = [
    {
        name: ThemesEnum.DEFAULT,
        properties: {
            '--header-bg': '#111',
            '--footer-bg': '#111',
            '--sidebar-bg': '#111',

            '--header-text-color': '#fff',
            '--footer-text-color': '#A0A0A0',
            '--header-border-color': '#A59466',

            '--tuke-color': '#A59466',
        }
    },
    {
        name: ThemesEnum.EKF,
        properties: {
            '--header-bg': '#111',
            '--footer-bg': '#111',
            '--sidebar-bg': '#111',

            '--header-text-color': '#fff',
            '--footer-text-color': '#A0A0A0',
            '--header-border-color': '#A51890',

            '--tuke-color': '#A59466'
        }
    },
    {
        name: ThemesEnum.FBERG,
        properties: {
            '--header-bg': '#111',
            '--footer-bg': '#111',
            '--sidebar-bg': '#111',

            '--header-text-color': '#fff',
            '--footer-text-color': '#A0A0A0',
            '--header-border-color': '#00843D',

            '--tuke-color': '#A59466'
        }
    },
    {
        name: ThemesEnum.FEI,
        properties: {
            '--header-bg': '#111',
            '--footer-bg': '#111',
            '--sidebar-bg': '#111',

            '--header-text-color': '#fff',
            '--footer-text-color': '#A0A0A0',
            '--header-border-color': '#EAAA00',

            '--tuke-color': '#A59466'
        }
    },
    {
        name: ThemesEnum.FMMR,
        properties: {
            '--header-bg': '#111',
            '--footer-bg': '#111',
            '--sidebar-bg': '#111',

            '--header-text-color': '#fff',
            '--footer-text-color': '#A0A0A0',
            '--header-border-color': '#BA0C2F',

            '--tuke-color': '#A59466'
        }
    },
    {
        name: ThemesEnum.FU,
        properties: {
            '--header-bg': '#111',
            '--footer-bg': '#111',
            '--sidebar-bg': '#111',

            '--header-text-color': '#fff',
            '--footer-text-color': '#A0A0A0',
            '--header-border-color': '#84BD00',

            '--tuke-color': '#A59466'
        }
    },
    {
        name: ThemesEnum.FVT,
        properties: {
            '--header-bg': '#111',
            '--footer-bg': '#111',
            '--sidebar-bg': '#111',

            '--header-text-color': '#fff',
            '--footer-text-color': '#A0A0A0',
            '--header-border-color': '#5F259F',

            '--tuke-color': '#A59466'
        }
    },
    {
        name: ThemesEnum.LF,
        properties: {
            '--header-bg': '#111',
            '--footer-bg': '#111',
            '--sidebar-bg': '#111',

            '--header-text-color': '#fff',
            '--footer-text-color': '#A0A0A0',
            '--header-border-color': '#41B6E6',

            '--tuke-color': '#A59466'
        }
    },
    {
        name: ThemesEnum.SJF,
        properties: {
            '--header-bg': '#111',
            '--footer-bg': '#111',
            '--sidebar-bg': '#111',

            '--header-text-color': '#fff',
            '--footer-text-color': '#A0A0A0',
            '--header-border-color': '#003DA5',

            '--tuke-color': '#A59466'
        }
    },
    {
        name: ThemesEnum.SVF,
        properties: {
            '--header-bg': '#111',
            '--footer-bg': '#111',
            '--sidebar-bg': '#111',

            '--header-text-color': '#fff',
            '--footer-text-color': '#A0A0A0',
            '--header-border-color': '#E57200',

            '--tuke-color': '#A59466'
        }
    }
]
