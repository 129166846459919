import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {AppRoutesEnum} from '../app-routes.enum';

@Component({
  selector: 'app-dev',
  templateUrl: './dev.component.html',
  styleUrls: ['./dev.component.scss'],
})
export class DevComponent implements OnInit, OnDestroy {
  userLoadedRemotely: boolean;

  private routeSub: Subscription;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.routeSub = this.route.queryParams.subscribe(params => {

      const userParam = decodeURIComponent(params.user);

      if (userParam) {
        this.userLoadedRemotely = true;

        this.router.navigate([AppRoutesEnum.dashboard]);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }
}
