// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lang-switcher-pos {
  position: relative;
}

.selected {
  display: flex;
  align-items: center;
}

.icon-lang {
  width: 25px;
  flex: 0 0 25px;
  padding: 5px;
  box-sizing: content-box;
  cursor: pointer;
}
.icon-lang img {
  max-width: 100%;
}

.arrow {
  color: #888;
  font-size: 20px;
  line-height: 1px;
}

.arrow-icon {
  transition: 0.2s;
}

.arrow-icon--rotated {
  transform: rotate(180deg);
}

.available-languages {
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.3s;
}

.lang {
  padding: 5px 5px 0;
  box-sizing: content-box;
  cursor: pointer;
}
.lang img {
  width: 25px;
}

.available-languages--hidden {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
}`, "",{"version":3,"sources":["webpack://./src/app/common/i18n/lang-switcher/lang-switcher.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,WAAA;EACA,cAAA;EACA,YAAA;EACA,uBAAA;EACA,eAAA;AACF;AACE;EACE,eAAA;AACJ;;AAGA;EACE,WAAA;EACA,eAAA;EACA,gBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,yBAAA;AAAF;;AAGA;EACE,kBAAA;EACA,OAAA;EACA,MAAA;EACA,oBAAA;AAAF;;AAGA;EACE,kBAAA;EACA,uBAAA;EACA,eAAA;AAAF;AAEE;EACE,WAAA;AAAJ;;AAIA;EACE,UAAA;EACA,kBAAA;EACA,4BAAA;AADF","sourcesContent":[".lang-switcher-pos {\n  position: relative;\n}\n\n.selected {\n  display: flex;\n  align-items: center;\n}\n\n.icon-lang {\n  width: 25px;\n  flex: 0 0 25px;\n  padding: 5px;\n  box-sizing: content-box;\n  cursor: pointer;\n\n  img {\n    max-width: 100%;\n  }\n}\n\n.arrow {\n  color: #888;\n  font-size: 20px;\n  line-height: 1px;\n}\n\n.arrow-icon {\n  transition: 0.2s;\n}\n\n.arrow-icon--rotated {\n  transform: rotate(180deg);\n}\n\n.available-languages {\n  position: absolute;\n  left: 0;\n  top: 0;\n  transition: all 0.3s;\n}\n\n.lang {\n  padding: 5px 5px 0;\n  box-sizing: content-box;\n  cursor: pointer;\n\n  img {\n    width: 25px;\n  }\n}\n\n.available-languages--hidden {\n  opacity: 0;\n  visibility: hidden;\n  transform: translateY(-10px);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
