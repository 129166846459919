export const ALLOWED_ROLE_PREFIX = {
    EPC: 'EPC',
    ETD: 'ETD',
    ISBN: 'ISBN',
    ROOMBOOK: 'ROOMBOOK',
    ZP: 'ZP',
    UIK: 'UIK',
    HELPDESK: 'HELPDESK',
    TOOLS: 'TOOLS',
    LIBADMIN: 'LIBADMIN',
    ALFRED: 'ALFRED',
    HELIOS: 'HELIOS',
    AUTOPREVADZKA: 'AUTOPREVADZKA'
};

export const ALLOWED_GENERAL_ROLES = [
    'SUPERADMIN',
    'STUDENT',
    'PHD',
    'PEDAGOGUE',
    'EMPLOYEE'
];
