import {Component, OnInit} from '@angular/core';
import {AppTranslationService} from '../app-translation.service';
import {environment} from '../../../../environments/environment.dev';
import {isoCode2languageType, nameForLanguageType} from '../lang-functions';

interface Language {
    code: string;
    name: string;
    icon: string;
}

@Component({
    selector: 'app-lang-switcher',
    templateUrl: './lang-switcher.component.html',
    styleUrls: ['./lang-switcher.component.scss']
})
export class LangSwitcherComponent implements OnInit {
    public availableLanguages: Language[] = [];

    public currentLanguage: Language | undefined;

    public expanded = false;

    constructor(
        protected appTranslateService: AppTranslationService,
    ) {

        this.availableLanguages = environment.availableLanguages.map(
            (langCode) => ({
                code: langCode,
                name: nameForLanguageType(isoCode2languageType(langCode)),
                icon: 'assets/img/flags/' + langCode + '.png',
            })
        );

        this.currentLanguage = this.availableLanguages.find((l) => l.code === this.appTranslateService.currentLanguage);
        if (!this.currentLanguage) {
            this.currentLanguage = this.availableLanguages[0];
        }

        this.appTranslateService.changedLanguage.subscribe(
            (newLang) => {
                this.currentLanguage = this.availableLanguages.find((l) => l.code === newLang);
            }
        );

    }

    ngOnInit(): void {

    }

    clickedIcon(e: Event) {
        e.stopPropagation();
        this.expanded = !this.expanded;
    }

    async selectLanguage(lang: Language) {
        await this.appTranslateService.switchLanguage(lang.code);
        this.expanded = false;
    }

}

